{
    font: 12px sans-serif
;
}

.ant-table.ant-table-middle .ant-table-tbody .ant-table-wrapper:only-child .ant-table{
    margin-left: 50px;
    margin-right:10px;
    margin-top: 10px;
    margin-bottom:10px;
}


.ant-layout-sider-collapsed .facility-card {
    display: none;
}

.sticky-sidebar-footer .ant-card-meta-title {
    white-space: break-spaces;
    font-size: 12px;
}

.treemap-tab .ant-tabs-tabpane, .treemap-tab .ant-tabs-nav-wrap {
    padding-left: 0rem;
    padding-right: 0rem;
}

.ant-collapse > .ant-collapse-item > .ant-collapse-header {
    padding: 0 !important;

}

.ant-collapse-header {
    align-items: center !important;
}

.ant-collapse-content {
    padding: 0 !important;
}

.ant-collapse-content > .ant-collapse-content-box {
    padding: 0 !important;
}

/* benchmark tab*/
.tab_blue_bottom .ant-tabs-nav {
    border-bottom: 2px solid #2574a9;

}

.ant-tabs-tab.ant-tabs-tab-active {
    background-color: #E7F3FD;
    padding-left: 10px;
    padding-right: 10px;
    animation: bkAnim .135s cubic-bezier(0.39, 0.575, 0.565, 1) both;
}

.tab_blue_bottom .ant-tabs-ink-bar.ant-tabs-ink-bar-animated {
    top: 0;
    height: 3px;
}

.tab_blue_bottom .ant-tabs-ink-bar {
    background: #2974AA !important;
}

@media (min-width: 750px) {
    .ant-tabs-nav-wrap, .ant-tabs-tabpane {
        padding-left: 2.5rem;
        padding-right: 2.5rem;
    }


}

@media (max-width: 750px) {
    .ant-tabs-nav-wrap, .ant-tabs-tabpane {
        padding-left: 0.2rem;
        padding-right: 0.2rem;
    }
}

/**/

.scroll-border .ant-table {
    background-image: linear-gradient(to bottom, rgb(244, 245, 247) 16px, transparent 16px),
    linear-gradient(to bottom, rgba(9, 30, 66, 0.13) 0px, rgba(99, 114, 130, 0) 16px),
    linear-gradient(to top, rgba(9, 30, 66, 0.13) 0px, rgba(99, 114, 130, 0) 16px);
    min-height: 900px;
}

.planning_editor_50 {
    width: 50% !important;
}

.planning_editor_30 {
    width: 40% !important;
}

.planning_progress_50 {
    width: 50%
}

.planning_progress_70 {
    width: 60%
}

.ant-collapse-content {
    padding-left: 2rem;
    padding-right: 2rem;
}

.header-0-2-3 {
    font-size: 12px !important;
    letter-spacing: 0 !important;
    height: 3.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
}

button.ant-btn.ant-btn-primary.float-adduser-button {
    position: absolute;
    right: 20px;
    margin-top: 10px;
}

.analysis .ant-page-header-content {
    margin-top: -10px;
}

@media (max-width: 850px) {
    .toolbar {
        padding-bottom: 0.5rem;
    }

    .toolbar > div {
        margin-top: 0.5rem !important;
        margin: auto !important;
    }

    .ant-tabs > .ant-tabs-nav .ant-tabs-nav-wrap {
        justify-content: center;
    }

}

.ant-table-column-title {
    white-space: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
}


/*.table-text-right td.ant-table-cell, .table-text-right th.ant-table-cell {*/
/*    text-align: right !important*/
/*}*/

/*th.ant-table-cell.ant-table-cell-fix-left {*/
/*    background-color: #e3f0fb;*/
/*}*/
.ant-form-item-label label, .ant-table-content th {
    color: #6A74A5 !important;
    font-size: 12px !important;
    font-weight: 600;
    text-align: left;
}

.Center span.ant-input-affix-wrapper.ant-select-selection-search-input {
    border-radius: 1rem;
}

.Center span.ant-input-affix-wrapper.ant-select-selection-search-input input {
    text-align: center
}

.ant-input-affix-wrapper, textarea, input, .ant-select-selector {
    border-color: #E6E9F4 !important;
}

.ant-input-number-input {
    height: auto !important;
}

.ant-input-number {
    padding: 6px 2px !important;
}

.title-truncate {
    /*max-width: 12rem;*/
}

/*savings*/
.saving-stat {
    color: #6A74A5;
    font-weight: 700;
}



.benchmark-filter .ant-checkbox-wrapper{
    margin-top:10px;
    margin-bottom:10px;
}

.small-title {
    color: #131523;
    margin-bottom: 1rem;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 700;
}

.left-0-2-4 {
    padding: 10px !important;
}

.current-0-2-9 {
    font-size: 28px !important;
}

.small-title_piechart {
    color: #131523;
    padding-bottom: 1rem;
    font-size: 15px;
    line-height: 1.5;
    font-weight: 700;
}

.ant-btn-primary, .ant-radio-button-checked {
    background-image: linear-gradient(#409EFF, #407EFF);
}

/*antd*/

.ant-tabs-tab-btn {
    font-size: 14px;
    font-weight: 700;
}

.ant-form-item .ant-select {
    width: 100% !important
}

.anticon svg {
    height: 1em;
    width: 1em;
}

.g2plot > div {
    z-index: 9;
}

.overlay-top {
    z-index: 9;
}

a {
    color: #1890ff !important;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color .3s;
    -webkit-text-decoration-skip: objects;
}

.react-geocoder input {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    line-height: 1.5715;
    background-color: #fff;
    background-image: none;
    border: 1px solid #d9d9d9;
    border-radius: 2px;
    transition: all 0.3s;
}


.common-table .table-auto table {
    table-layout: auto !important;
}

.horizontal {
    display: flex;
    align-items: center;
}

.space-around {
    display: flex;
    justify-content: space-around;
}

th.ant-table-cell {
    background: #f5f5f5;
    color: #999;
    height: 17px;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    cursor: cell;
    vertical-align: middle;


}

.ant-table-cell, .ant-table.ant-table-middle .ant-table-tbody > tr > td, .ant-table.ant-table-middle tfoot > tr > th {
    padding: 0px !important;
}

/*#myForm .ant-form-item {*/
/*    border-bottom: 1px solid grey;*/
/*}*/

/*#myForm .ant-row.ant-form-item {*/
/*    margin-bottom: 1rem !important;*/
/*}*/

/*#myForm .ant-form-item-control-input-content input {*/
/*    margin-bottom: -15px;*/
/*}*/

.ant-table.ant-table-middle .ant-table-title, .ant-table.ant-table-middle .ant-table-footer, .ant-table.ant-table-middle .ant-table-thead > tr > th, .ant-table.ant-table-middle tfoot > tr > th {
    padding: 2px !important;
}

.disabled-input {
    color: black !important;
    background-color: #f5f5f5;
    cursor: not-allowed;
    opacity: 1;
    box-sizing: border-box;
    margin: 0;
    font-variant: tabular-nums;
    list-style: none;
    font-feature-settings: 'tnum', "tnum";
    position: relative;
    display: inline-block;
    width: 100%;
    min-width: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.85);
    font-size: 12px;
    line-height: 1.5715;
    background-image: none;
    border: 1px solid #d9d9d9;

    transition: all 0.3s;
}

body {
    background-color: #1b2431;
    color: #202020;
    /*font-family: "Montserrat", "Helvetica", "Open Sans", "Arial" !important;*/
    font-family: 'Poppins', sans-serif !important;
    font-size: 12px;
}


form#myForm {
    column-gap: 32px;
    display: flex;
    flex-wrap: wrap;
}

.ant-page-header-heading-extra {
    display: flex;
}

.round-button {
    z-index: 16;
    border-radius: 27px !important;
    box-shadow: 0px 4px 16px 0px rgba(179, 192, 231, 1) !important;
    width: 100%;
    justify-content: center;
    align-items: center !important;
}

.ant-page-header-footer {
    margin-top: 0 !important;
}

input#basic_username {
    padding-left: 1rem !important;
}

.ant-btn {
    display: flex !important;
}

.spin {
    position: absolute !important;
    top: 2%;
    left: 50%;
    z-index: 9999;
}

input#basic_password {
    border: none !important;
}

/*input.ant-input, .ant-input-affix-wrapper-lg, .ant-input-affix-wrapper {*/
/*    border: 0.5px solid #d9d9d95c !important;*/

/*}*/

.ant-input-affix-wrapper > input.ant-input {
    border: none !important;
}

.ant-radio-button-wrapper:first-child {
    border-radius: 2rem 0 0 2rem !important;
    overflow: hidden;
}


.ant-radio-button-wrapper {
    overflow: hidden;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 2rem 2rem 0 !important;
    overflow: hidden;
}

.ant-btn {
    display: flex;
    align-items: center;
}

/*input.ant-input {*/
/*    padding-left: 1rem !important;*/
/*}*/

.cardWrapper {
    width: 100%;
}

.outerCardWrapper {
    color: rgb(97, 97, 97);
    transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
    background-image: none;
    overflow: hidden;
    /*border: 1px solid rgba(144, 202, 249, 0.46) !important;*/


    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border: inherit !important;
    background-clip: border-box;
    border-radius: 7px;
    box-shadow: 0 4px 25px 0 rgb(168 180 208 / 10%);

}

.tableWrapper {
    border-bottom: 0px;
    border-radius: 3px;
    box-shadow: rgb(0 0 0 / 30%) 0px 1px 5px;
    flex: 1 1 auto;
    min-width: 0px;
}

.center {
    display: flex;
    margin: auto;
    margin-top: 0.5rem;
}

.ant-upload-list-item-error {
    pointer-events: none !important;
}

input::placeholder {
    font-size: 12px !important;
}

.ant-descriptions-bordered .ant-descriptions-item-label, .ant-descriptions-bordered .ant-descriptions-item-content {
    padding: 10px 24px !important;
}

.related .ant-card-head {
    padding: 0 24px;
    background: #fafafa !important;
    font-weight: bold !important;
}

.bottom-separator {
    margin-bottom: 24px !important;
}

.ant-descriptions-item-label {
    font-weight: bold !important;
}


form.ant-form.ant-form-vertical.ant-pro-form-query-filter {
    padding-bottom: 12px;
}

.ant-pro-table-list-toolbar-container, .ant-pro-table-alert-info {
    display: flex;
    justify-content: space-between;
}

.ant-pro-table-list-toolbar {
    padding: 16px 0;
}

.main-container, .main-wrapper {
    background: #fff;
    position: relative
}

.main-container {
    min-height: 500px;
    padding: 0 170px 32px 64px
}

.markdown {
    color: rgba(0, 0, 0, .85);
    font-size: 12px;
    line-height: 2
}

.markdown h1 {
    color: rgba(0, 0, 0, .85);
    font-size: 30px;
    font-weight: 500;
    line-height: 38px;
    margin-bottom: 20px;
    margin-top: 8px
}

.markdown h1 .subtitle {
    margin-left: 12px
}

.markdown h2 {
    font-size: 24px;
    line-height: 32px
}

.markdown h2, .markdown h3, .markdown h4, .markdown h5, .markdown h6 {
    clear: both;
    color: rgba(0, 0, 0, .85);
    font-weight: 500;
    margin: 1.6em 0 .6em
}

.markdown h3 {
    font-size: 18px
}

.markdown h4 {
    font-size: 16px
}

.markdown h5 {
    font-size: 14px
}

.markdown h6 {
    font-size: 12px
}

.markdown hr {
    background: #f0f0f0;
    border: 0;
    clear: both;
    height: 1px;
    margin: 56px 0
}

.markdown p, .markdown pre {
    margin: 1em 0
}

.ant-row-rtl .markdown p, .ant-row-rtl .markdown pre {
    direction: rtl;
    text-align: right
}

.markdown ul > li {
    list-style-type: circle;
    margin-left: 20px;
    padding-left: 4px
}

.rtl .markdown ul > li {
    margin-left: 0;
    margin-right: 20px;
    padding-left: 0;
    padding-right: 4px
}

.markdown ul > li:empty {
    display: none
}

.markdown ol > li {
    list-style-type: decimal;
    margin-left: 20px;
    padding-left: 4px
}

.ant-row-rtl .markdown ol > li {
    margin-left: 0;
    margin-right: 20px;
    padding-left: 0;
    padding-right: 4px
}

.markdown ol > li > p, .markdown ul > li > p {
    margin: .2em 0
}

.markdown code {
    background: #f2f4f5;
    border: 1px solid #f0f0f0;
    border-radius: 3px;
    font-size: .9em;
    margin: 0 1px;
    padding: .2em .4em
}

.markdown pre {
    background: #f2f4f5;
    border-radius: 2px;
}

.markdown pre code {
    background: #f5f5f5;
    border: none;
    color: rgba(0, 0, 0, .85);
    direction: ltr;
    font-size: 12px;
    margin: 0;
    overflow: auto;
    padding: 0;
    text-align: left
}

.markdown b, .markdown strong {
    font-weight: 500
}

td.ant-table-cell {
    height: 2rem;
}

/*.ant-table-content {*/
/*    overflow: scroll;*/
/*}*/

.ant-popover-buttons {
    display: flex;
}

tr.selected_row.ant-table-row:hover > td {
    background: darkseagreen !important;
    cursor: pointer;
}


.selected_row {
    background-image: linear-gradient(to right, darkseagreen, white);
    -webkit-animation: fadin 0.8s;
    -moz-animation: fadein 0.8s;
    -ms-animation: fadein 0.8s;
    -o-animation: fadein 0.8s;
    animation: fadein 0.8s;
}

.selected_row_pink {
    background-color: pink;
    -webkit-animation: fadin 0.8s;
    -moz-animation: fadein 0.8s;
    -ms-animation: fadein 0.8s;
    -o-animation: fadein 0.8s;
    animation: fadein 0.8s;
}

@keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-moz-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-webkit-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-ms-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@-o-keyframes fadein {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.ant-table table, .ant-form, .nt-tab, .ant-input, .ant-select, .ant-picker, .ant-picker-input input, .ant-page-header,
.ant-card, .ant-radio-button-wrapper, .ant-select-item, .ant-btn, .ant-pagination, .ant-input-number, .ant-form label {
    font-size: 12px !important;
    color: #3A3F63;
}

.ant-picker {
    border: 1px solid #E6E9F4 !important;
}

.ant-tabs-tab {
    font-size: 14px !important;
}

.ant-space.ant-space-horizontal.ant-space-align-center {
    gap: 3px !important;
}

.ant-page-header {
    padding: 0 !important
}

.ant-btn > .anticon + span, .ant-btn > span + .anticon {
    margin-right: 8px;
}

.ant-input-group .ant-input {
    min-height: 2rem
}

.ant-divider-horizontal {
    margin: -0px 0 12px 0 !important;
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background: #f0f2f5;
}

.ant-modal-close {
    top: -15px;
}

label.ant-radio-button-wrapper {
    color: #1890ff;
}

.ant-steps {
    width: auto;
    min-width: 400px;
}

.ant-steps-navigation .ant-steps-item-container {
    padding-bottom: 2px;
}

.ant-steps-navigation {
    padding-top: 2px;
}

/*.ant-table {*/
/*    overflow: scroll;*/
/*}*/

thead.ant-table-thead th {
    white-space: nowrap;
}

.ant-form-item-control-input-content .ant-picker {
    width: 100%;
    padding: 6px 11px 6px !important;
}

.ant-table-thead th.ant-table-cell, th {
    text-transform: none;
}

.ant-table-thead > tr > th, .ant-table-column-title {
    font-size: 12px;
    text-transform: uppercase !important;
}

.ant-table-thead th.ant-table-cell::first-letter, th::first-letter {
    text-transform: uppercase;
}


.ant-table-cell {
    white-space: normal !important;
}

.ant-steps-small.ant-steps-horizontal:not(.ant-steps-label-vertical) .ant-steps-item {
    padding-left: 35px;
}

/*override antd style*/
.blue-btn {
    color: #1890ff;
}

.green-btn {
    color: green !important;
}


.ant-table-thead > tr > th, .ant-table-tbody > tr > td, .ant-table tfoot > tr > th, .ant-table tfoot > tr > td {
    padding: 4px;
}

.compact-table td:nth-child(2), .compact-table td:nth-child(5) {
    padding-left: 1rem;
}

.icon {
    margin-right: 0.6rem;
}

.level3-sec {
    margin-top: 0.15rem;
}

tr.Profile4Row td {
    padding: 0.2rem 0;
    font-size: 11px;
}

.benchmark {
    text-align: left;
    width: 0.5rem;
    overflow: hidden;
}

.limit {
    width: 0.5rem;
    padding-left: 0.2rem;
}

.word-wrap {
    display: block;
    width: 200px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.Sidebar .DashboardMenu a {
    color: #fff !important;
}

.Sidebar .DashboardMenu .ant-menu-item {
    height: 60px !important;
}

.corner-flower-purple {
    color: #fff;
    overflow: hidden;
    position: relative;
    background-color: #5e35b1 !important;
}

.corner-flower-purple:before {
    top: -125px;
    right: -15px;
    width: 210px;
    height: 210px;
    content: "";
    opacity: 0.5;
    position: absolute;
    background: #4527a0;
    border-radius: 50%;
}

.corner-flower-purple:after {
    top: -85px;
    right: -95px;
    width: 210px;
    height: 210px;
    content: "";
    position: absolute;
    background: #4527a047;
    border-radius: 50%;
}

/**/

.corner-flower-orange {
    color: #fff;
    overflow: hidden;
    position: relative;
    background-color: #dbb47b !important;
}

.corner-flower-orange:before {
    top: -125px;
    right: -15px;
    width: 210px;
    height: 210px;
    content: "";
    opacity: 0.5;
    position: absolute;
    background: #DA932C;
    border-radius: 50%;
}

.corner-flower-orange:after {
    top: -85px;
    right: -95px;
    width: 210px;
    height: 210px;
    content: "";
    position: absolute;
    background: #da932c4d;
    border-radius: 50%;
}


/**/

.corner-flower-green {
    color: #fff;
    overflow: hidden;
    position: relative;
    background-color: #a4d582 !important;
}

.corner-flower-green:before {
    top: -125px;
    right: -15px;
    width: 210px;
    height: 210px;
    content: "";
    opacity: 0.5;
    position: absolute;
    background: #66B92E;
    border-radius: 50%;
}

.corner-flower-green:after {
    top: -85px;
    right: -95px;
    width: 210px;
    height: 210px;
    content: "";
    position: absolute;
    background: #66b92e4f;
    border-radius: 50%;
}


/**/

.corner-flower-red {
    color: #fff;
    overflow: hidden;
    position: relative;
    background-color: #D38D85 !important;
}

.corner-flower-red:before {
    top: -125px;
    right: -15px;
    width: 210px;
    height: 210px;
    content: "";
    opacity: 0.5;
    position: absolute;
    background: #D65B4A;
    border-radius: 50%;
}

.corner-flower-red:after {
    top: -85px;
    right: -95px;
    width: 210px;
    height: 210px;
    content: "";
    position: absolute;
    background: #d65b4a3b;
    border-radius: 50%;
}

.calendar, .day-name, .event-text {
    font-size: 12px !important;
}

#myForm .ant-input-affix-wrapper, #myForm .ant-select-selector, #myForm .ant-input {
    border-radius: 8px;
    background: #fff;
    /*border-width: 1px;*/
}

#basic .InputWrapper .ant-input-affix-wrapper-lg {
    padding: 6px 11px !important
}


.ant-picker {
    min-height: 2rem;
}

.ant-table-row-level-0 .subject-editor {
    font-weight: bold
}


#export-button a {
    color: #fff !important;
}

#export-button {
    margin-left: 1px !important;
}

#myForm .ant-input {
    padding: 6px 11px !important;
}

#myForm .ant-input-affix-wrapper {
    padding: 0px 11px !important;

}

/*table*/

.global-search .ant-input-affix-wrapper {
    padding: 0 !important;
}
